<template>
  <div class="column ">
    <div class="tile is-ancestor is-parent ">
      <div class="tile ">
        <div class="tile is-parent is-vertical p-0 m-0 ">

          <article class="tile is-child box  p-0 m-0"
            v-if="!partsStatusScreen.show && !purchaseOrderScreen.show">
            <div class="columns p-0 m-0 sticky-heading"
              style="margin-top: 12px !important">
              <div class="column p-0 m-0 is-6 ">
                <div class="px-3 is-size-4 has-text-weight-bold">Parts Control - Not Ordered
                </div>
              </div>

              <div class="column p-0 m-0 is-6 ">
                <div class="is-flex is-flex-direction-row-reverse">
                  <div class="field">
                    <div v-if="userPartsCheckPermission()"
                      class="button mr-3"
                      @click="$emit('downloadPartsCheckPrice')"
                      :class="{'button is-success': value.parts.filter((p) => p.deleted === false && !p.isNew && (p.partStatus == 'Exp' || p.partStatus == 'Imp')).length > 0,
                               'disabled' : value.parts.filter((p) => p.deleted === false && !p.isNew && (p.partStatus == 'Exp' || p.partStatus == 'Imp')).length > 0 ? false : true
                      }">
                      <span>Sync Prices</span>
                      <span class="icon">
                        <i class="mdi mdi-arrow-down" />
                      </span>
                    </div>
                  </div>
                </div>
              </div>

            </div>

            <!-- Live price grid, code commented end of file -->

            <!-- Unordered parts -->
            <quote-parts-control-not-ordered :value="value"
              :snapshot-diff="snapshotDiff"
              :parts-check-settings="partsCheckSettings"
              :unordered-parts="unorderedParts"
              @newPurchaseOrder="(itemsToPurchase, key) => handleCreateNewPurchaseOrder(itemsToPurchase, key)"
              @goToPriceParts="goToPriceParts"
              @goToPartsCheck="goToPartsCheck" />

            <div class="columns p-0 m-0 sticky-heading"
              style="margin-top: 12px !important">

              <div class="column p-0 m-0 is-full ">
                <div class="px-3 is-size-4  has-text-weight-bold">
                  Parts Control - Ordered
                </div>
              </div>
            </div>

            <circle-8 v-if="isLoading"
              :message="'Loading Ordered Parts'" />

            <!-- Ordered parts -->
            <div v-for="(purchaseOrder, index) in purchaseOrders"
              :key="purchaseOrder.purchaseOrderId"
              class="glow"
              :class="{'active': purchaseOrder.purchaseOrderId == newPoId}"
              :id="purchaseOrder.purchaseOrderId">
              <quote-parts-control-ordered :value="value"
                :id="index + '-parts-control-ordered-parts-group' + purchaseOrder.purchaseOrderId"
                :purchase-order="purchaseOrder"
                @printReturnOrder="printReturnOrder"
                @handleReturnOrder="(orderReceipt, purchaseOrder) => handleReturnOrder(orderReceipt, purchaseOrder)"
                @handleEditOrder="(orderReceipt, purchaseOrder) => handleEditOrder(orderReceipt, purchaseOrder)"
                @goToPartsCheck="goToPartsCheck"
                @changeItemStatus="changeItemStatus" />
            </div>

            <!-- No ordered Items -->
            <div v-if="purchaseOrders.length === 0"
              class="p-3">
              <div class="notification is-grey-dark">

                No ordered parts
              </div>
            </div>

          </article>

          <!-- screens to make new pos, recieve, return and cancel items -->
          <article class="tile is-child box detail-page-tile p-0 m-0"
            v-else>

            <!-- New purchase Order -->
            <parts-control-new-purchase-order v-if="purchaseOrderScreen.show == partsControlEnums.newPo.key"
              :quote-id="value.quoteId"
              :quote="value"
              :quote-no="value.quoteNo"
              :insurer-id="value.insurerId"
              :customer-id="value.customerId"
              :unordered-parts="purchaseOrderScreen.itemsToPurchase"
              @close="(message, refreshQuote = false, purchaseOrderId) => handleOnClose(message, refreshQuote, purchaseOrderId)" />

            <!-- Manual Quote -->
            <parts-control-manual-quote v-if="purchaseOrderScreen.show == partsControlEnums.manualQuote.key"
              :quote="value"
              :unordered-parts="purchaseOrderScreen.itemsToPurchase"
              @updateUnorderedPartStatus="updateUnorderedPartStatus"
              @close="(message, refreshQuote = false, purchaseOrderId) => handleOnClose(message, refreshQuote, purchaseOrderId)" />

            <!-- Receive Parts -->
            <parts-control-recieve-parts v-if="partsStatusScreen.status == partsControlEnums.receive.key"
              :purchase-order="partsStatusScreen.purchaseOrder"
              :order-receipt="partsStatusScreen.orderReceipt"
              :selected-items="partsStatusScreen.selectedItems"
              :is-editable="permissions.hasEdit"
              @close="(message, refreshQuote = false) => handleOnClose(message, refreshQuote)" />

            <!-- Cancel Parts -->
            <parts-control-cancel-parts v-if="partsStatusScreen.status == partsControlEnums.cancel.key"
              :purchase-order="partsStatusScreen.purchaseOrder"
              :selected-items="partsStatusScreen.selectedItems"
              @close="(message, refreshQuote = false) => handleOnClose(message, refreshQuote)" />

            <!-- Return Parts -->
            <parts-control-return-parts v-if="partsStatusScreen.status == partsControlEnums.return.key"
              :purchase-order="partsStatusScreen.purchaseOrder"
              :order-receipt="partsStatusScreen.orderReceipt"
              @printReturnOrder="printReturnOrder"
              :selected-items="partsStatusScreen.selectedItems"
              @close="(message, refreshQuote = false) => handleOnClose(message, refreshQuote)" />
          </article>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
import PartsCheckService from '@/services/PartsCheckService'
import PurchaseOrderService from '@/services/PurchaseOrderService'
import { customFormatDateLocale } from '@/utils/DateFunctions'
import QuotePartsControlNotOrdered from './unorderedparts/QuotePartsControlNotOrdered.vue'
import QuotePartsControlOrdered from './orderedParts/QuotePartsControlOrdered.vue'
import { PartsControlActionTypes } from '@/enums'
import PartsControlRecieveParts from './orderControlScreens/receiveParts/PartsControlRecieveParts.vue'
import PartsControlCancelParts from './orderControlScreens/PartsControlCancelParts.vue'
import PartsControlReturnParts from './orderControlScreens/returnParts/PartsControlReturnParts.vue'
import PartsControlNewPurchaseOrder from './unorderedparts/PartsControlNewManualPurchaseOrder.vue'
import QuoteRoutes from '../route-types'
import Circle8 from '@/components/Spinners/Circle8.vue'
import PartsControlManualQuote from './unorderedparts/PartsControlManualQuote.vue'
// import { QuotePartMixin } from '../mixins'
import { CompanyService } from '../services'
import StoreUtil from '@/store/utils'
import { KeyValuePairModel } from '@/classes/viewmodels'
import StoreMixin from './storeMixin'
import { Emailer } from '@/classes'
import PrintPreviewRoutes from '@/components/printpreview/route-types'
import { PartStatusTypes } from '../../../enums'

export default {
  name: 'QuotePartsControl',
  components: {
    QuotePartsControlNotOrdered,
    QuotePartsControlOrdered,
    PartsControlRecieveParts,
    PartsControlCancelParts,
    PartsControlReturnParts,
    PartsControlNewPurchaseOrder,
    Circle8,
    PartsControlManualQuote
  },
  filters: {
    formatDate: function (value, locale) {
      const options = {
        weekday: 'short',
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      }
      return customFormatDateLocale(value, locale, options)
    }
  },
  mixins: [StoreMixin],

  props: {
    value: null,
    snapshotDiff: null
  },
  data() {
    return {
      isLoading: false,

      openSubRows: [],
      purchaseOrders: [],
      activePartsCheckRequest: 0,
      partsCheckPrices: [],
      // controlling ordered parts
      // receive, cancel, return
      partsStatusScreen: {
        show: false,
        purchaseOrder: {},
        selectedItems: [],
        orderReceipt: null,
        scrollPosition: 0
      },
      // creating new purchase order
      purchaseOrderScreen: {
        show: false,
        itemsToPurchase: [],
        quote: {},
        scrollPosition: 0
      },
      // when a new po is created, this is the id of the new po, for css animation
      newPoId: null,

      partsCheckSettings: null
    }
  },
  computed: {
    quotes() {
      return [this.value]
    },
    partsControlEnums() {
      return PartsControlActionTypes
    },
    unorderedParts() {
      let lines = []
      for (let quote of this.quotes) {
        lines = [
          ...lines,
          /// quantity ordered is less than total quantity or quantity ordered - quantity returned is less than total quantity
          ...quote.parts.filter((r) => !r.isNew && (r.quantityOrdered < r.itemQuantity || r.quantityOrdered - r.quantityReturned < r.itemQuantity)),
          ...quote.sublets.filter((r) => !r.quantityOrdered) // sublets dont have quantity figure
        ]
      }
      return lines.filter((i) => i.isDeleted === false || i.isNew === true)
    },
    permissions() {
      return this.$user.permissions?.find((p) => p.name === 'Purchase Order' || p.codeName === 'A106') || null
    }
  },
  async created() {
    this.getPurchaseOrdersForQuote()
    // this.fetchPartsCheckPrices()

    this.getPartsCheckSettings()
  },
  methods: {
    async getPartsCheckSettings() {
      this.partsCheckSettings = await CompanyService.getExternalSetting('partscheck')
    },

    userPartsCheckPermission() {
      let isPartsCheck = false
      for (let permissions of this.$user.permissions) {
        if (permissions.codeName == 'A021') {
          isPartsCheck = permissions.hasAccess && !!this.partsCheckSettings && this.partsCheckSettings.property2 == '1'
        }
      }
      return isPartsCheck
    },

    changeItemStatus(status, purchaseOrder, selectedItems) {
      if (!this.permissions.hasEdit) {
        // this.$toast.open({
        //   type: 'is-danger',
        //   position: 'is-top',
        //   message: 'You do not have permission to ' + status + ' Purchase Orders',
        //   queue: false
        // })
        this.$notification.error('', 'You do not have permission to ' + status + ' Purchase Orders')

        return
      }

      let el = document.querySelector('.app-content')
      this.partsStatusScreen.status = status
      this.partsStatusScreen.show = true
      this.partsStatusScreen.purchaseOrder = purchaseOrder
      this.partsStatusScreen.selectedItems = selectedItems
      this.partsStatusScreen.scrollPosition = el.scrollTop
      // scroll to top of the page when going inside status screens, return
      // to the same position when closing the status screen and back on the main page
      el.scrollTop = 0
    },

    handleCreateNewPurchaseOrder(itemsToPurchase, key) {
      const message = key === 'newPo' ? 'Create New Purchase Order' : 'Create Parts Enquiry'
      if (!this.permissions.hasEdit) {
        // this.$toast.open({
        //   type: 'is-danger',
        //   position: 'is-top',
        //   message: 'You do not have permission to ' + message,
        //   queue: false
        // })
        this.$notification.error('', 'You do not have permission to ' + message)

        return
      }

      if (!!this.snapshotDiff) {
        this.$notification.error('Error', 'Please save Quote before making changes in Parts Control')
        return
      }

      let el = document.querySelector('.app-content')
      this.purchaseOrderScreen.show = key
      this.purchaseOrderScreen.itemsToPurchase = itemsToPurchase
      this.purchaseOrderScreen.quote = this.value
      this.purchaseOrderScreen.scrollPosition = el.scrollTop
      // scroll to top of the page when going inside status screens, return
      // to the same position when closing the status screen and back on the main page
      el.scrollTop = 0
    },

    //////////// return and edit order functions used for order receipts ////////////
    handleReturnOrder(orderReceipt, purchaseOrder) {
      if (!this.permissions.hasEdit) {
        // this.$toast.open({
        //   type: 'is-danger',
        //   position: 'is-top',
        //   message: 'You do not have permission to Return Orders',
        //   queue: false
        // })
        this.$notification.error('', 'You do not have permission to Return Orders')

        return
      }

      let el = document.querySelector('.app-content')
      this.partsStatusScreen.status = this.partsControlEnums.return.key
      this.partsStatusScreen.show = true
      this.partsStatusScreen.purchaseOrder = purchaseOrder
      this.partsStatusScreen.orderReceipt = orderReceipt
      this.partsStatusScreen.scrollPosition = el.scrollTop
      // scroll to top of the page when going inside status screens, return
      // to the same position when closing the status screen and back on the main page
      el.scrollTop = 0
    },
    handleEditOrder(orderReceipt, purchaseOrder) {
      let el = document.querySelector('.app-content')
      this.partsStatusScreen.status = this.partsControlEnums.receive.key
      this.partsStatusScreen.show = true
      this.partsStatusScreen.purchaseOrder = purchaseOrder
      this.partsStatusScreen.orderReceipt = orderReceipt
      this.partsStatusScreen.scrollPosition = el.scrollTop
      // scroll to top of the page when going inside status screens, return
      // to the same position when closing the status screen and back on the main page
      el.scrollTop = 0
    },
    scrollToPurchaseOrder(id) {
      const el = document.getElementById(id)
      this.newPoId = id
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center'
      })
      this.removeClass()
    },
    // 4 sec timeout before removing the animation class
    async removeClass() {
      setTimeout(() => {
        this.newPoId = null
      }, 4000)
    },
    async getPurchaseOrdersForQuote(purchaseOrderId = null) {
      this.isLoading = true
      let purchaseOrders = await PurchaseOrderService.getPurchaseOrdersForQuote(this.value.quoteId)
      this.purchaseOrders = purchaseOrders.sort((a, b) => new Date(a.createdDate) - new Date(b.createdDate))
      this.isLoading = false
      // if a purchase order id is passed, scroll to the purchase order
      this.$nextTick(() => {
        if (!!purchaseOrderId) {
          this.scrollToPurchaseOrder(purchaseOrderId)
        }
      })
    },
    handleOnClose(successMessage = null, reFreshQuote = false, purchaseOrderId = null) {
      if (successMessage) this.$notification.success(successMessage)
      let scrollPosition = null
      // only scroll to th e historic position if the status screen was open
      if (this.partsStatusScreen.show) {
        scrollPosition = this.partsStatusScreen.scrollPosition
        this.partsStatusScreen = {
          show: false,
          purchaseOrder: {},
          status: '',
          selectedItems: [],
          orderReceipt: null,
          scrollPosition: 0
        }
      } else if (this.purchaseOrderScreen.show) {
        // scrollPosition = this.purchaseOrderScreen.scrollPosition
        this.purchaseOrderScreen = {
          show: false,
          itemsToPurchase: [],
          quote: {},
          scrollPosition: 0
        }
      }

      // scroll to the position before the status screen was opened
      let el = document.querySelector('.app-content')

      this.$nextTick(() => {
        if (!!scrollPosition) el.scrollBy(0, scrollPosition)
      })

      this.$nextTick(() => {
        if (reFreshQuote) this.$emit('getEntity', true)
        // refreshing the quote itself
        this.getPurchaseOrdersForQuote(purchaseOrderId)
      })
    },

    async fetchPartsCheckPrices() {
      const pcPrices = await PartsCheckService.getLivePrices(this.value.quoteId)
      this.partsCheckPrices = pcPrices
    },
    async savePartsCheckRequest(request) {
      let selectedLines = []
      for (let line of request.tableData.idxLineItems.lines) {
        if (line.poID != null) continue

        line.priceMatrix.forEach((pmr) => {
          Object.values(pmr).forEach((row) => {
            if (row.price == null) return
            if (row.price.isSelected == '1') {
              selectedLines.push(row)
            }
          })
        })
      }
      let packet = {
        draftId: request.tableData.draftInfo.draftID_PK,
        updateQuotePackage: true,
        purchaseItems: [],
        selectItems: []
      }

      for (let item of selectedLines) {
        let itemPacket = {
          draftItemId: item.price.draftItemID_PK,
          supplierId: item.supplier.clientID_PK,
          itemTypeId: item.price.itemTypeID_PK,
          modifiedPartNumber: item.price.supplierPartNr,
          priceId: item.price.priceID_PK,
          lineListPrice: item.price.priceListOriginal,
          markupValue: item.price.priceListOriginal,
          markupType: 'uselist',
          lineCostPrice: item.price.priceValue,
          supplierPartNumber: item.price.supplierPartNr,
          quantity: item.price.p_qty,
          unitCostPrice: item.price.priceValue / item.price.p_qty,
          standardPartNumberId: 25
        }
        packet.selectItems.push(itemPacket)
      }

      this.partsCheckPrices = await PartsCheckService.saveLivePrices(this.value.quoteId, packet)
      this.fetchPartsCheckPrices()
    },
    async purchasePartsCheckRequest(request) {
      let packet = {
        draftId: request.tableData.draftInfo.draftID_PK,
        preferredSupplyDate: '24/11/2023',
        note: 'I wanna buy stuff [PETER]',
        updateQuotePackage: true,
        notSelectedReasons: []
      }
      this.partsCheckPrices = await PartsCheckService.purchasePricedParts(this.value.quoteId, packet)
      this.fetchPartsCheckPrices()
    },
    togglePriceSelection(line, supplier) {
      let value = line.priceMatrix[0][supplier.clientID_PK].price.isSelected
      for (var supplierId in line.priceMatrix[0]) {
        if (supplierId == supplier.clientID_PK || line.priceMatrix[0][supplierId].price == null) {
          continue
        }
        line.priceMatrix[0][supplierId].price.isSelected = '0'
      }
      if (line.poID == null) {
        line.priceMatrix[0][supplier.clientID_PK].price.isSelected = value == '1' ? '0' : '1'
      }
    },
    getPriceForCell(line, supplier) {
      // FIX THE 0 NEEDS TO BE SOMETHING MORE
      return line.priceMatrix[0][supplier.clientID_PK]
    },
    getOrderedSupplierList(request) {
      return Object.values(request.tableData.idxSupplierList).sort((a, b) => {
        return a.typeID_PK.localeCompare(b.typeID_PK)
      })
    },
    toggleSubRow(item) {
      this.openSubRows[item.quoteItemId] = !(this.openSubRows[item.quoteItemId] ?? 0)
    },
    goToPriceParts(unorderedParts) {
      if (this.snapshotDiff) {
        this.$notification.error('Price Parts', 'Please save the quote before you start PartsCheck Price request')
        return false
      }

      if (unorderedParts.filter((p) => p.deleted === false && p.isNew).length > 0) {
        this.$notification.openNotificationWithType(
          'danger',
          'Price Parts',
          'You have added new part item(s), save quote before you start PartsCheck Price request'
        )
        return false
      }
      this.$nextTick(() => {
        // set a return path to the current page
        this.$router.push({
          name: QuoteRoutes.QuotePriceParts.name
        })
      })
    },

    async goToPartsCheck(e, partsCheckReferenceNo, searchType, part) {
      // stops the event from bubbling up the DOM tree and opening/expanding the item
      // e.stopPropagation()
      // e.preventDefault()

      // Not priced Yet
      if (!part.partStatus) {
        // this.$toast.open({
        //   message: 'Please request the part before proceeding to PartsCheck.',
        //   type: 'is-warning',
        //   position: 'is-top',
        //   queue: false
        // })
        this.$notification.warning('', 'Please request the part before proceeding to PartsCheck.')

        return
      } else if (
        ///// requested and/or priced
        (part.partStatus === 'Exp' && part.quantityOrdered == 0 && part.quantityReceived == 0) ||
        (part.partStatus === 'Imp' && part.quantityOrdered == 0 && part.quantityReceived == 0) ||
        (part.partStatus === 'Pord' && part.quantityOrdered == 0 && part.quantityReceived == 0)
      ) {
        const quoteNo = this.value.prefix + this.value.quoteNo + this.value.suffix
        const result = await PartsCheckService.goToPartsCheck(quoteNo, this.value.subQuoteNo, partsCheckReferenceNo, searchType)
        if (result) window.open(result, '_blank')
      } else if (
        ///// ordered, received, returned, etc.
        (part.quantityOrdered > 0 && part.quantityReceived == 0 && part.quantityReturned == 0) ||
        (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered == part.quantityReceived) ||
        (part.quantityOrdered > 0 && part.quantityReceived > 0 && part.quantityOrdered !== part.quantityReceived) ||
        (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered == part.quantityReturned) ||
        (part.quantityOrdered > 0 && part.quantityReturned > 0 && part.quantityOrdered !== part.quantityReturned)
      ) {
        const quoteNo = this.value.prefix + this.value.quoteNo + this.value.suffix
        const result = await PartsCheckService.goToPartsCheck(quoteNo, this.value.subQuoteNo, partsCheckReferenceNo, 'po')
        if (result) window.open(result, '_blank')
      }
    },

    updateUnorderedPartStatus(itemIds, status, vendor) {
      this.unorderedParts.forEach((part) => {
        if (itemIds.includes(part.quoteItemId)) {
          if (
            ![PartStatusTypes.sentPartsCheckEnquiry.toLocaleLowerCase(), PartStatusTypes.pricedPartsControl.toLocaleLowerCase()].includes(
              part.partStatus?.toLowerCase()
            )
          ) {
            part.partStatus = status
            part.vendorName = vendor.name
            part.vendorId = vendor.id
          }
        }
      })
      this.handleOnClose('Sent Enquiry to ' + vendor.name, false)
      this.$nextTick(() => {
        this.$emit('save')
      })
    },

    printReturnOrder(creditNo, vendorId) {
      let params = Object.assign({
        REPORTTITLE: 'Credit Return',
        CompanyID: this.$userInfo.companyId,
        CREDITNO: creditNo,
        VendorID: vendorId
      })

      const keyValuePairs = KeyValuePairModel.convertToKeyValuePairs(params)
      this.addReportParameters(keyValuePairs)
      const parameterId = this.$guid.newGuid()
      StoreUtil.setLocalStorage(parameterId, 'parameters', keyValuePairs)

      const emailer = new Emailer()
      emailer.subject = 'Return Parts List'
      emailer.reportName = 'Return_Part_List'

      this.addEmailer(emailer)
      this.$router.push({
        name: PrintPreviewRoutes.PrintPreview.name,
        params: { reportName: 'AT_CREDITRETURN' },
        query: { parameterId: this.$guid.newGuid(), emailerId: emailer.id }
      })
    },
    convertToKeyValuePairs(parameters) {
      const keyValuePairs = Object.keys(parameters).map(function (key) {
        return new KeyValuePairModel(key, parameters[key])
      })
      return keyValuePairs
    }
  }
}
</script>

<style lang="scss">
@import '../../../assets/style/themes/default/_variables.scss';

////// styling for unordered and ordered parts
.header {
  height: 45px;
  border-bottom: 1px solid #adb5bd;
  margin-bottom: 5px;
  div:not(.checkbox-component) {
    display: flex;
    align-items: center;
    justify-content: center;
    text-overflow: ellipsis;
    // overflow-x: hidden;
    white-space: nowrap;
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }

    &.left {
      justify-content: flex-start;
    }
  }
}
.part-row {
  height: 50px !important;
  border: 1px solid #adb5bd;
  border-radius: 5px;
  width: 100%;
  font-size: 0.9em;
  margin-bottom: 5px;

  &.interactive {
    &:hover {
      cursor: pointer;
      border-color: $primary !important;
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.disable-pointer-events {
    pointer-events: none;
    opacity: 0.8;
  }

  div {
    padding: 5px 0px 5px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    &.center {
      justify-content: center;
    }
    &.right {
      justify-content: flex-end;
    }
    &.left {
      justify-content: flex-start;
    }
  }
}

.item-deleted {
  opacity: 0.5;
  position: relative;

  .line-through {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    border-top: 1px solid #777777;
    z-index: 1;
  }
}

$background-color: #f5f5f5;

.sticky-heading {
  position: sticky;
  top: calc(0px);
  z-index: 9;
}

//glow effect
.glow {
  transition: all 0.5s ease-in-out;

  &.active {
    animation: glow 2s infinite linear;
    @keyframes glow {
      0% {
        background-color: mix(black, $background-color, 0%);
      }
      50% {
        background-color: mix(black, $background-color, 10%);
        box-shadow: 0 0 5px 4px $primary;
      }
      100% {
        background-color: mix(black, $background-color, 0%);
      }
    }
  }
}

.full-quote-page-height {
  height: fit-content;
  // 52px for the navbar and 52px for the quote header, 20px for the padding
  min-height: calc(100vh - 52px - 52px - 20px) !important;
}
</style>

<style scoped>
.spinner {
  min-height: 0px !important;
}
</style>

            <!-- <div v-if="true">
                <div class="tabs">
                  <ul>
                    <li v-for="(request,index) in partsCheckPrices"
                      :key="request.draftID_PK"
                      :class="{'is-active': activePartsCheckRequest == index}"
                      @click="activePartsCheckRequest = index"><a>Request {{ index +1 }}</a></li>
                  </ul>
                </div>
                <template v-for="(request,index) in partsCheckPrices">
                  <div :key="request.draftID_PK"
                    v-if="index==activePartsCheckRequest">
                    <div>Request {{ index +1 }}</div>
                    <div class="button is-primary is-outlined"
                      @click="savePartsCheckRequest(request)">Save</div>
                    <div class="button is-primary is-outlined"
                      @click="purchasePartsCheckRequest(request)">Purchase</div>
                    <table border="1">
                      <tr>
                        <td>xxxx</td>
                        <td v-for="supplier in getOrderedSupplierList(request)"
                          :key="supplier.clientID_PK">{{ supplier.clientName }}</td>
                      </tr>
                      <tr v-for="line in request.tableData.idxLineItems.lines"
                        :key="line.draftItemID">
                        <td>{{ line.importPartDescription }}</td>
                        <template v-for="supplier in getOrderedSupplierList(request)">
                          <td :key="supplier.clientID_PK"
                            :class="{'has-background-danger': line?.poPriceID == getPriceForCell(line, supplier)?.price?.priceID_PK,'has-background-info': line.poID == null && getPriceForCell(line, supplier)?.price?.isSelected == '1'}"
                            @click="togglePriceSelection(line,supplier)">
                            {{ getPriceForCell(line, supplier)?.price?.priceValue }}
                          </td>
                        </template>
                      </tr>
                    </table>
                  </div>
                </template>
              </div> -->