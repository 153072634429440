<template>
  <app-side-menu>
    <aside
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input
                class="input is-uppercase"
                type="text"
                placeholder="Rego"
                v-model="value.rego"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                class="input is-uppercase"
                type="text"
                placeholder="Claim No."
                v-model="value.claimNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                class="input is-uppercase"
                type="text"
                placeholder="Quote No."
                v-model="value.quoteNo"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button
            class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button
            class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'

export default {
  name: 'EstimageMessageListSideMenu',
  components: {
    AppSideMenu
  },
  props: {
    value: null
  },
  data() {
    return {}
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {},
  mounted() {},
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.$emit('reset')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
