import routeTypes from './route-types'
// import store from '@/store'

const EstimageMessageListView = (r) => require.ensure([], () => r(require('./EstimageMessageListView.vue')), 'estimagemessagelist-chunk')
const EstimageMessageView = (r) => require.ensure([], () => r(require('./EstimageMessageView.vue')), 'estimagemessageview-chunk')
const EstimageMessageDetail = (r) => require.ensure([], () => r(require('./EstimageMessageDetail.vue')), 'estimagemessageview-chunk')
const EstimageMessageNew = (r) => require.ensure([], () => r(require('./EstimageMessageNew.vue')), 'estimagemessage-chunk')

const menuCode = 'A200'

export default [
  {
    path: routeTypes.EstimageMessageListView.path,
    name: routeTypes.EstimageMessageListView.name,
    component: EstimageMessageListView,
    meta: {
      id: menuCode,
      route: 'listing',
      title: 'EstImage Messages',
      fkey: 'estimagemessage|filter',
      report: '',
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    }
  },
  {
    path: routeTypes.EstimageMessageView.path,
    name: '',
    component: EstimageMessageView,
    meta: {
      id: menuCode,
      route: 'detail',
      title: 'EstImage Messages',
      isNew: false,
      reportUrl: `${process.env.VUE_APP_ROOT_URI}/modules/reports/printpreview.aspx`
    },
    children: [
      {
        path: '',
        name: routeTypes.EstimageMessageView.name,
        component: EstimageMessageDetail,
        meta: {
          id: menuCode,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      }
    ]
  },
  {
    path: routeTypes.EstimageMessageNew.path,
    name: '',
    component: EstimageMessageNew,
    meta: {
      id: menuCode,
      route: 'detail',
      isNew: true,
      title: 'New EstImage Message'
    },
    children: [
      {
        path: '',
        name: routeTypes.EstimageMessageNew.name,
        component: EstimageMessageDetail,
        meta: {
          id: menuCode,
          route: 'detail',
          title: 'Detail',
          isNew: false
        }
      }
    ]
  }
]
