export default {
  QML_Unknown: 0,
  QML_RequestForQuote: 1,
  QML_ScopeOfWork: 2,
  QML_DeclinedRepair: 3,
  QML_Quote: 4,
  QML_AuthorityWithdrawn: 5,
  QML_JobCancelled: 6,
  QML_TotalLoss: 7,
  QML_TaxInvoice: 8,
  QML_AdjustmentNote: 9,
  QML_ReactivateInvoice: 10,
  QML_PaymentAuthorised: 11,
  QML_PaymentDeclined: 12,
  QML_Image: 13,
  QML_Error: 14,
  QML_QDEVersionChange: 15,
  QML_QDEReferenceData: 16,
  QREQ: 17,
  ADQT: 18,
  AUQT: 19,
  ARES: 20,
  RRQT: 21,
  QRCN: 22,
  QTDL: 23,
  BACA: 24,
  QMQR: 25,
  EWAP: 26,
  MANQ: 27,
  BCQR: 28,
  OUTBOUND_QUOTE: 29,
  UPDATE_REPAIR_STATUS: 30,
  TAX_INVOICE: 31,
  PNET_IMAGE: 32,
  AudaNetDownloadQuote: 33,
  AudaNetClaimInfo: 34,
  AudaNetVehicleInfo: 35,
  AudaNetSubmitParts: 36,
  AudaNetSubmitSublets: 37,
  AutoIntegritySubmitQuote: 38,
  EstImageRequestForQuote: 42,
  EstImageAuthorise: 43,
  EstImageCancel: 44,
  EstImageRegoUpdate: 45,
  EstImageExcessUpdate: 46,
  EstimageQuote: 47,
  EstimageInvoice: 48,
  EstimageVehicle: 49,
  EstimageImage: 50,
  EstimageRepairTimeline: 51
}
