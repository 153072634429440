import FqApi from '@/services/fq-api'
import ProtoBuf from 'protobufjs'
import AuditTrailApi from '@/services/audittrail-api'
// import axios from 'axios'

export default {
  async getEntitySummaries(filter) {
    const serialised = this.serialiseMessageFilter(filter)
    const url = `/estimagemessages/list/${serialised}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getCountSummaries() {
    const url = '/estimagemessages/list/counts'
    const result = await FqApi.get(url)
    return result.data
  },
  serialiseMessageFilter(filter) {
    const proto = require('../../assets/proto/estimage/EstimageFilter.proto')
    const filterMessage = proto.EstimageFilter.EstimageFilterModel
    const buffer = filterMessage.encode(filter).finish()
    const b64encoded = ProtoBuf.util.base64.encode(buffer, 0, buffer.length)
    return b64encoded
  },
  async getEntity(id) {
    const url = `/estimagemessages/${id}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getNewEntity() {
    const res = await FqApi.get('/estimagemessages/new')
    return res.data
  },
  putEntity(entity, deepdiff) {
    if (deepdiff) {
      entity.deepDiff = JSON.stringify(deepdiff)
    }
    return FqApi.put(`/estimagemessages/${entity.messageId}`, entity)
  },
  updateMessageStatus(messageId, statusId) {
    return FqApi.put(`/estimagemessages/${messageId}?statusId=${statusId}`)
  },
  async postEntity(entity) {
    return FqApi.post(`/estimagemessages/${entity.messageId}`, entity)
  },
  // async getInsurerSummaries() {
  //   const url = '/estimagemessages/insurerlist'
  //   const result = await FqApi.get(url)
  //   return result.data
  // },
  loadMessage(options) {
    return FqApi.put(`/estimagemessages/load/${options.messageId}`, options)
  },
  // async getQuoteIdByNo(quoteNo) {
  //   const result = await FqApi.get(`quotes/quoteidbyno?quoteNo=${quoteNo}`)
  //   return result.data
  // },
  async assertQuoteExists(claimNo, rego, jobId) {
    const url = `/estimagemessages/load/quoteexists?claimNo=${encodeURIComponent(claimNo)}&rego=${encodeURIComponent(rego)}&jobId=${encodeURIComponent(jobId)}`
    const result = await FqApi.get(url)
    return result.data
  },
  async assertVehicleExists(rego) {
    const url = `/estimagemessages/load/vehicleexists?rego=${encodeURIComponent(rego)}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteNo(quoteId) {
    const url = `/estimagemessages/load/getquoteno/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  async getQuoteEntity(quoteId) {
    const url = `/quotes/${quoteId}`
    const result = await FqApi.get(url)
    return result.data
  },
  // async getToken() {
  //   const secretToken = '7eebTEusd5GcfzPpAfcgiwyni9hLICm3'
  //   const result = await axios.get(`/audittrail/v1/auth/token?authToken=${secretToken}`)
  //   console.log(result.data)
  //   console.log(result.status)
  // },
  // async auditTrailTest() {
  //   const result = await AuditTrailApi.get('/v1/audittrails/test')
  //   console.log(result.data)
  // },
  async postAuditTrailItem(audtiTrailItem) {
    return AuditTrailApi.post('/v1/audittrails/audittrailitem', audtiTrailItem)
  }
}
