<template>
  <div v-if="entity">
    <app-detail-header :show-toggle="true"
      :show-new="false"
      :show-print="false"
      :show-delete="false"
      :show-save="entity !== null"
      @appDetailHeaderButtonClicked="onHeaderButtonClicked" />
    <div class="columns is-gapless">
      <div class="column is-narrow">
        <estimage-message-new-side-menu v-if="entity"
          v-model="estimageDetails" />
      </div>
      <div class="column">
        <estimage-message-detail v-if="options && showEditor && entity"
          v-model="entity.messageJson"
          :options="options" />
      </div>
    </div>
    <unsaved-modal :active.sync="isUnsavedModalActive"
      @close="closeModal()"
      @skipSave="skipSave()"
      @saveContinue="saveContinue()">
      <p slot="text-title">Unsaved Changes</p>
      <p slot="text-content">There are unsaved changes. Please select action below</p>
    </unsaved-modal>
    <save-conflict-modal :active.sync="isSaveConflictModalActive"
      @close="closeModal()"
      @reload="reloadData()">
      <p slot="text-title">Change conflict</p>
      <p slot="text-content">The data on the server is newer than the local copy. Please reload local data.</p>
    </save-conflict-modal>
  </div>
</template>

<script>
import EstimageMessageService from './EstimageMessageService'
import StoreMixin from './storeMixin'
import EstimageMessageNewSideMenu from './EstimageMessageNewSideMenu'
import EstimageMessageDetail from './EstimageMessageDetail'
import EstimageMessageRoutes from './route-types'
import AppDetailHeader from '@/components/AppDetailHeader'
import { AppHeaderButtonTypes, EventHubTypes } from '@/enums'
import HttpStatus from '@/components/http-status'
import { UnsavedModal, SaveConflictModal } from '@/components/BulmaModal'
import _cloneDeep from 'lodash/cloneDeep'
import _debounce from 'lodash.debounce'
import EventBus from '@/components/EventBus'
import store from '@/store'

export default {
  name: 'EstimageMessageNew',
  components: {
    AppDetailHeader,
    EstimageMessageNewSideMenu,
    EstimageMessageDetail,
    UnsavedModal,
    SaveConflictModal
  },
  mixins: [StoreMixin],
  data() {
    return {
      options: {
        tabSize: 4,
        styleActiveLine: true,
        lineNumbers: true,
        autoCloseTags: true,
        foldGutter: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter'],
        highlightSelectionMatches: { showToken: /\w/, annotateScrollbar: true },
        line: true,
        matchBrackets: true,
        showCursorWhenSelecting: true,
        lineSeparator: '\n',
        lineWrapping: true,
        mode: 'application/xml',
        theme: 'default'
      },
      entity: null,
      estimageDetails: [],
      showEditor: true,
      isUnsavedModalActive: false,
      isSaveConflictModalActive: false,
      isConfirmModalActive: false,
      isSkipSave: false,
      isSaveContinue: false,
      toRoute: null
    }
  },
  computed: {},
  watch: {
    entity: {
      handler: _debounce(function (newVal) {
        if (newVal) {
          this.$forceUpdate()
          this.saveSnapshot(_cloneDeep(this.entity))
        }
      }, 250),
      deep: true
    }
  },
  beforeRouteEnter(to, from, next) {
    if (store.state.userInfo.data.isSupportUser == null || !store.state.userInfo.data.isSupportUser) {
      next('/estimagemessages')
    } else {
      next()
    }
  },
  created() {
    this.getEntity()
    if (this.$userInfo.codemirrorTheme) {
      this.options.theme = this.$userInfo.codemirrorTheme
    }
  },
  mounted() {},
  methods: {
    onHeaderButtonClicked(action) {
      switch (action) {
        case AppHeaderButtonTypes.AddNew:
          break
        case AppHeaderButtonTypes.Print:
          break
        case AppHeaderButtonTypes.Delete:
          break
        case AppHeaderButtonTypes.Save:
          this.save()
          break
        case AppHeaderButtonTypes.Cancel:
          this.cancel()
          break
      }
    },
    async getEntity(reset = false) {
      try {
        this.$showSpinner()
        if (reset) {
          this.clearSnapshots(this.$route.params.messageId)
        }
        if (!this.currentSnapshot) {
          await this.getStoreItem(this.$route.params.messageId)
        }
        this.entity = _cloneDeep(this.currentSnapshot)
      } catch (e) {
        this.$notification.openMessageXhrError('', e)
      }
      this.$hideSpinner()
    },
    async save(isDelete) {
      const title = 'EstImage Message'
      this.entity.messageDirection = parseInt(this.estimageDetails.messageDirection)
      this.entity.messageTypeId = this.estimageDetails.messageTypeEnum
      this.entity.statusId = this.estimageDetails.messageStatusTypeEnum
      this.entity.uid = this.estimageDetails.uid
      this.entity.modifiedBy = this.$userInfo.userId
      this.entity.createdBy = this.$userInfo.userId
      this.entity.messageNo = parseInt(this.estimageDetails.messageNo)
      if (this.entity.messageJson === '' || this.entity.messageNo === undefined || this.entity.uid === undefined) {
        this.$notification.openNotificationWithType('danger', title, 'Validation errors. Please fix before saving')
        return false
      }
      try {
        this.$showSpinner('Saving...')
        let response
        this.saveSnapshot(_cloneDeep(this.entity))
        if (this.entity.isNew) {
          response = await EstimageMessageService.postEntity(this.entity)
        } else if (this.snapshotDiff) {
          response = await EstimageMessageService.putEntity(this.entity, this.snapshotDiff)
        } else {
          this.$notification.openNotificationWithType('warning', title, 'No changes. Not saved')
        }
        this.$hideSpinner()
        if (this.isSaveContinue) {
          this.$notification.openNotificationWithType('success', title, isDelete ? 'EstImage Messages deleted' : 'Save successful')
        } else if (response && response.status === HttpStatus.NO_CONTENT) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, isDelete ? 'EstImage Messages deleted' : 'Save successful')
        } else if (response && response.status === HttpStatus.CREATED) {
          await this.getEntity(true)
          this.$notification.openNotificationWithType('success', title, 'New EstImage Messages added')
        }
        this.$eventHub.$emit(EventHubTypes.EntitySaved)
        return true
      } catch (e) {
        EventBus.$emit('serviceError', e.response.status)
        this.$hideSpinner()
        if (e.response.request.status === HttpStatus.CONFLICT) {
          this.isSaveConflictModalActive = true
        } else {
          this.$notification.openMessageXhrError('EstImage Messages', e)
        }
        return false
      }
    },
    async saveContinue() {
      this.isUnsavedModalActive = false
      this.isSaveContinue = true
      this.isSaveContinue = await this.save()
      if (this.isSaveContinue) {
        this.cancel()
      }
    },
    skipSave() {
      this.isUnsavedModalActive = false
      this.isSkipSave = true
      this.cancel()
    },
    closeModal() {
      this.isUnsavedModalActive = false
      this.isSaveConflictModalActive = false
      this.isSaveContinue = false
    },
    cancel() {
      this.saveSnapshot(_cloneDeep(this.entity))
      if (this.entity.messageJson === '' || this.entity.messageNo === undefined || this.entity.uid === undefined) {
        this.$router.push({
          name: EstimageMessageRoutes.EstimageMessageListView.name
        })
      } else if (this.snapshotDiff && !this.isSkipSave && !this.isSaveContinue) {
        this.isUnsavedModalActive = true
      } else {
        if (this.toRoute) {
          this.$router.push(this.toRoute.fullPath)
        } else {
          this.$router.push({
            name: EstimageMessageRoutes.EstimageMessageListView.name
          })
        }
      }
    }
  },
  beforeRouteLeave: async function (to, from, next) {
    this.clearSnapshots(this.entity.messageId)
    next()
  }
}
</script>