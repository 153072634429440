<template>
  <div>
    <div class="field">
      <div class="control">
        <input class="is-checkradio"
          id="create"
          type="radio"
          name="create"
          :value="true"
          :disabled="innerValue.quoteExist"
          v-model="innerValue.isCreateQuote">
        <label for="create">Create new quote</label>
      </div>
    </div>
    <div
      class="field">
      <div class="control">
        <input class="is-checkradio"
          id="assign"
          type="radio"
          name="assign"
          :value="false"
          v-model="innerValue.isCreateQuote">
        <label for="assign">Assign quote</label>
      </div>
    </div>
    <div v-if="(ecomType.toLowerCase() === 'orm' || ecomType.toLowerCase() === 'audanet'|| ecomType.toLowerCase() === 'estimage') && !innerValue.isCreateQuote">
      <load-quote-assign
        v-model="value"
        :rego-no="value.regoNo"
        :ecom-type="ecomType" />
    </div>
    <div v-if="ecomType === 'pnet' && !innerValue.isCreateQuote">
      <div class="control">
        <input class="input is-uppercase"
          :class="{ 'is-danger' : !validQuoteNo }"
          v-model="quoteNo"
          @input="searchQuote"
          placeholder="Quote No."
          v-focus-inserted>
      </div>
      <span v-if="validQuoteNo && $v.loadOptions.quoteId.$dirty && !quoteIsAuthorised && !checkingQuoteIsAuthorised"
        class="help is-success">Valid Quote No.</span>
      <span v-if="!validQuoteNo && !quoteIsAuthorised && !checkingQuoteIsAuthorised"
        class="has-text-danger is-flex align-items-center">
        <span class="icon has-text-danger">
          <i class="mdi mdi-alert-octagram mdi-20px" />
        </span>
        Please enter an existing Quote No.
      </span>
      <span v-if="quoteIsAuthorised && !checkingQuoteIsAuthorised"
        class="has-text-danger is-flex align-items-center">
        <span class="icon has-text-danger">
          <i class="mdi mdi-alert-octagram mdi-20px" />
        </span>
        This quote has been authorised already.
      </span>
      <span v-if="checkingQuoteIsAuthorised"
        class="has-text-warning is-flex align-items-center">
        <span class="icon has-text-warning">
          <i class="mdi mdi-spin mdi-loading mdi-20px" />
        </span>
        Checking Quote...
      </span>
    </div>
  </div>
</template>

<script>
import Guid from '@/components/Guid'
import _debounce from 'lodash.debounce'
import LoadQuoteService from './LoadQuoteService'
import LoadQuoteAssign from './LoadQuoteAssign'
import { FocusInserted } from '@/components/directives'
import PnetMessageService from '@/views/pnetmessage/PnetMessageService.js'
import { EventHubTypes, PnetQuoteStatusTypes } from '@/enums'

export default {
  name: 'LoadQuoteCreateStep',
  inject: ['$vv'],
  components: {
    LoadQuoteAssign
  },
  directives: {
    FocusInserted
  },
  props: {
    value: null,
    ecomType: {
      type: String
    }
  },
  data() {
    return {
      quoteNo: '',
      innerValue: this.value,
      quoteIsAuthorised: false,
      checkingQuoteIsAuthorised: false
    }
  },
  computed: {
    validQuoteNo() {
      return !this.$v.loadOptions.quoteId.$error || this.value.isCreateQuote
    },
    $v() {
      return this.$vv || this.$v
    }
  },
  watch: {
    innerValue: {
      handler: function (newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    },
    checkingQuoteIsAuthorised: {
      handler: function (newVal, oldVal) {
        if (this.quoteIsAuthorised) {
          this.$eventHub.$emit(EventHubTypes.LoadQuoteNextStatus, false)
          return
        }
        this.$eventHub.$emit(EventHubTypes.LoadQuoteNextStatus, !newVal)
      }
    }
  },
  methods: {
    searchQuote: _debounce(async function () {
      this.$v.loadOptions.quoteId.$touch()
      this.quoteIsAuthorised = false
      if (!this.quoteNo) {
        this.innerValue.quoteId = Guid.empty()
        return
      }
      this.innerValue.quoteId = await LoadQuoteService.getQuoteIdByNo(this.quoteNo)
      if (this.innerValue.quoteId !== Guid.empty()){
        await this.checkQuoteAuthority()
      } else {
        this.$eventHub.$emit(EventHubTypes.LoadQuoteNextStatus, false)
      }
    }, 500),
    async checkQuoteAuthority() {
      try {
        this.checkingQuoteIsAuthorised = true
        const pnetQuote = await PnetMessageService.getQuotePnet(this.innerValue.quoteId)
        this.quoteIsAuthorised = pnetQuote.quoteId !== Guid.empty() &&
          pnetQuote.quoteStatusId == PnetQuoteStatusTypes.QuoteAuthorised
      } catch (e) {
        this.quoteIsAuthorised = false
      } finally {
        this.checkingQuoteIsAuthorised = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/style/mixins/inputs/placeholder';

input.is-uppercase {
  text-transform: uppercase;
  @include reset-placeholder-text-transform;
}
</style>
