exports.EstimageMessageListView = {
  path: '/estimagemessages',
  name: 'EstImageMessageListView'
}
exports.EstimageMessageView = {
  path: '/estimagemessages/:messageId',
  name: 'EstImageMessageView'
}
exports.EstimageMessageNew = {
  path: '/estimagemessages/new/:messageId',
  name: 'EstImageMessageNew'
}
